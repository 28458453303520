import React from 'react'
import { Document } from '@contentful/rich-text-types'
import { graphql } from 'gatsby'
import {
  Header,
  Layout,
  RichText,
  SinglePageContainer,
} from '../components/common'
import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import { ROUTES } from '../constants/routes'

type GroupProps = {
  data: {
    group: {
      name: string
      color: string
      initials: string
      mainPic: {
        fluid: FluidObject
      }
      slug: string
      description: {
        json: Document
      }
    }
  }
}

const Group: React.FC<GroupProps> = ({ data }) => (
  <Layout navigation={{ location: ROUTES.GROUP, slug: data.group.slug }}>
    <StyledImg
      alt="Immagine di gruppo"
      fluid={data.group.mainPic.fluid}
      loading="eager"
    />
    <Header
      color={data.group.color}
      title={data.group.name}
      subtitle={`Branca ${data.group.initials}`}
    />
    <SinglePageContainer>
      <RichText align="justify" content={data.group.description.json} />
    </SinglePageContainer>
  </Layout>
)

export default Group

export const pageQuery = graphql`
  query($id: String!) {
    group: contentfulGroup(id: { eq: $id }) {
      name
      color
      initials
      mainPic {
        fluid(maxHeight: 900, maxWidth: 1600, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      slug
      description {
        json
      }
    }
  }
`

const StyledImg = styled(Img)`
  margin-bottom: 1rem;
  max-height: 90vh;

  @media only screen and (min-width: 1600px) {
    margin: 0 auto 1rem;
    max-width: 1600px;
  }
`
